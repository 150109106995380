.link {
  text-decoration: none;
  color: var(--tertiary-color);
}

.container {
  background-color: var(--secondary-bg-color);
  border-radius: 15px;
  padding: 1rem;
}

.link:hover {
  color: var(--tertiary-color);
}

.container h2 {
  font-size: x-large;
}

.container h3 {
  font-size: medium;
}

.inner-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
