.app-container {
  overflow: hidden;
  /* margin: 0.75rem 1rem; */
  margin: 0;
  margin-bottom: 3rem;
}

@media screen and (min-width: 600px) {
  .app-container {
    /* margin: 2rem; */
    margin: 0;
    margin-top: 3rem;
  }
}