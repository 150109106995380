.title {
    margin-bottom: 1rem;
}

.container {
    margin: 1rem;
}

.projects {
    list-style: none;
    padding: 0;
}

@media screen and (min-width: 600px) {
    .title {
        display: none;
    }
}

@media screen and (min-width: 1100px) {
    .project {
        margin: auto;
        margin-bottom: 1rem;
        width: 1000px;
    }
}