:root {
  --typewriter-speed: 1.25s;
  --typewriter-characters: 26;
  --blink-speed: 1s;
}

.image {
  width: 100%;
  height: 30vh;
  object-fit: cover;
  object-position: left top;
  opacity: 0;
  transform: translateY(-1rem);
  animation: imageFadeIn 1s ease forwards;
}

.container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font-size: clamp(.75rem, 3vw + .75rem, 2.5rem);
  position: relative;
  font-family: "Source Code Pro", monospace;
  width: max-content;
}

.header::before,
.header::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.header::before {
  background-color: black;
  animation: typewriter var(--typewriter-speed)
    steps(var(--typewriter-characters)) 1s forwards;
}

.header::after {
  width: 0.125em;
  background-color: white;
  animation: typewriter var(--typewriter-speed)
      steps(var(--typewriter-characters)) 1s forwards,
    blink var(--blink-speed) steps(var(--typewriter-characters)) infinite;
}

.content {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: .25s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes imageFadeIn {
  to {
    transform: translateY(0);
    opacity: 100;
  }
}

@media screen and (min-width: 600px) {
  .image {
    height: 50vh;
  }

  .container {
    width: 50%;
    margin: auto;
    margin-top: 2rem;
    text-align: center;
  }
}
