.main h1 {
  margin-top: 0.5rem;
  text-align: center;
  width: 100%;
}

.main {
  margin: 1rem;
}

@media screen and (min-width: 900px) {
  .main {
    width: 75%;
    margin: auto;
    margin-top: 1rem;
  }
}
