.container {
  background-color: var(--secondary-bg-color);
  border-radius: 15px;
}

.card-content {
  padding: 1rem 1rem 1rem 1rem;
}

.project-url,
.project-url:hover {
  color: var(--ternary-color);
  text-decoration: none;
  background-color: var(--primary-color);
}

.project-url {
  border-radius: 5px;
  padding: 0.5rem;
}

@media screen and (max-width: 599px) {
  .card-button {
    text-align: center;
  }

  .card-image {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    object-position: center;
    border-radius: 15px 15px 0 0;
  }
}

@media screen and (min-width: 600px) {
  .container {
    display: flex;
  }

  .card-image {
    width: 30%;
    object-fit: cover;
    border-radius: 15px 0 0 15px;
  }
}
